@import-once 'breakpoints.css';
@import-once 'sizings.css';
@import-once "colors.css";
@import-once "typography.module.css";


.container {
	display: flex;
	user-select: none;
	margin-bottom: 0;
	width: auto;

	&:focus-within {
 		outline: var(--size-2) solid var(--color-primary);
	}
}

.content {

	@media (--tablet) {
		padding-inline-end: var(--size-8);
	}
}

.input {
	opacity: 0;
	width: 0;
	height: 0;
}

.label {
	background-color: var(--color-truewhite);
	border-radius: 3px;
	border: 1px solid var(--color-gray-lighter);
	cursor: pointer;
	padding: var(--size-4);
	position: relative;
	white-space: nowrap;


	&:not(.loading)::before {
		align-items: center;
		background-color: var(--color-primary);
		border-radius: 2px;
		bottom: var(--size-4);
		content: '';
		display: flex;
		left: var(--size-4);
		position: absolute;
		top: var(--size-4);
		transform: translateX(0);
		transition: background-color .2s linear,
			left .2s ease-in-out,
			transform .2s ease-in-out;
		width: 46%;
		z-index: 10;
	}
}

.servicesToggle .label::before {
	width: 57%;
}


.text {
	color: var(--color-gray-dark);
	cursor: pointer;
	font-size: var(--text-sm);
	font-weight: var(--font-bold);
	margin: 0 var(--size-4);
	position: relative;
	text-align: center;
	top: 0;
	transition: color .3s linear;
	width: var(--size-36);
	z-index: 20;
	text-transform: uppercase;

	label.loading & {
		opacity: 0;
	}


	@media (--tablet) {
		top: -1px;
	}
}

.toggleBtnWrapper {
	padding: var(--size-20) 0;
}


.textChecked {
	font-weight: var(--font-normal);
}

.textUnchecked {
	color: var(--color-truewhite);
}

.servicesToggle .textUnchecked {
	min-width: var(--size-56);
}


.input:checked + .label::before {
	left: calc(100% - 4px);
	transform: translateX(-100%);
}

.servicesToggle .input:checked + .label::before {
	width: 37%;
}

.input:checked + .label .textUnchecked {
	color: var(--color-gray-dark);
}

.input:checked + .label .textChecked {
	color: var(--color-truewhite);
}


.labelText {
	margin-bottom: 0;
	white-space: normal;
}

.help {
	font-size: var(--text-sm);
	margin-top: 0;
}
