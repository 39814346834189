@import-once "breakpoints.css";
@import-once "colors.css";
@import-once "sizings.css";
@import-once "typography.module.css";

.container {
	display: flex;
	justify-content: center;
	background-color: var(--color-gray-lightest);
	margin: var(--size-16) 0 var(--size-32);

	@media (--tablet) {
		justify-content: flex-end;
	}
}

.list {
	display: flex;
	align-items: center;
	list-style: none;
	margin: auto 0;
	padding: 0;
}

.link {
	display: block;
	padding: 0 var(--size-8);
}

.link,
.prev,
.next {
	background-color: var(--color-truewhite);
	border-radius: var(--size-4);
	border: var(--size-2) solid var(--color-primary);
	padding: var(--size-8) var(--size-14) var(--size-8);
	color: var(--color-primary);
	font-size: var(--text-sm);
	font-weight: var(--font-bold);
	line-height: 1;
	transition: background-color 0.3s linear, border-color 0.3s linear, color 0.3s linear;
	word-break: keep-all;

	&:hover {
		cursor: pointer;
	}
}

.link {
	margin: 0 var(--size-4);

	@media (--tablet) {
		margin: 0;
		border-radius: 0;
	}
}

.prev,
.next {
	@media (--tablet) {
		border-radius: var(--size-4);
	}
}

.prev {
	margin-right: var(--size-16);
}

.next {
	margin-left: var(--size-16);
}

.linkWrapper {
	position: relative;

	&:nth-child(2) .link {
		@media (--tablet) {
			border-radius: var(--size-4) 0 0 var(--size-4);
			border-right: 0;
		}
	}
	&:nth-last-child(2) .link {
		@media (--tablet) {
			border-radius: 0 var(--size-4) var(--size-4) 0;
			border-left: 0;
		}
	}
}

.firstLink .link {
	@media (--tablet) {
		border-radius: var(--size-4) 0 0 var(--size-4);
		border-right: 0;
	}
}

.lastLink .link {
	@media (--tablet) {
		border-radius: 0 var(--size-4) var(--size-4) 0;
		border-left: 0;
	}
}

.active .link {
	background-color: var(--color-primary);
	color: var(--color-truewhite);
}


.disabled .prev,
.disabled .next {
	cursor: default;
	pointer-events: none;
	background-color: var(--color-panel-bd);
	color: var(--color-gray-blue);
	border-color: var(--color-panel-bd);
	opacity: 0.6;
}
