@import-once 'breakpoints.css';
@import-once 'sizings.css';
@import-once "colors.css";
@import-once "typography.module.css";

:global(.tabs__tab-list) {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	border-bottom: 3px solid var(--color-primary);
}


:global(.tabs__tab-list-item) {
	display: flex;
	border: 1px solid var(--color-primary);
	border-left: 0;
	width: 50%;

	@media (--tablet) {
		flex-grow: 1;
	}
}


:global(.tabs__tab-list-item:first-of-type) {
	border-left: 1px solid var(--color-primary);
}

:global(.react-tabs__tab-list-item:first-of-type) {
	border-left: 1px solid var(--color-primary);
}

:global(.tabs__trigger) {
	width: 100%;
	text-align: center;
	color: var(--color-primary);
	text-decoration: none;
	padding: var(--size-12);
	transition: background-color .3s linear,
	border-color .3s linear,
	color .3s linear;


	&:hover {
		background-color: var(--color-primary);
		color: var(--color-truewhite);
		text-decoration: none;
	}

	&:focus,
	&:active {
		text-decoration: none;
	}
}

:global(.react-tabs__trigger) {
	width: 100%;
	text-align: center;
	color: var(--color-primary);
	text-decoration: none;
	padding: var(--size-12);
	transition: background-color .3s linear,
	border-color .3s linear,
	color .3s linear;


	&:hover {
		background-color: var(--color-primary);
		color: var(--color-truewhite);
		text-decoration: none;
	}

	&:focus,
	&:active {
		text-decoration: none;
	}
}

:global(.tabs__trigger.is-selected) {
	background-color: var(--color-primary);
	color: var(--color-truewhite);
}

:global(.react-tabs__trigger.is-selected) {
	background-color: var(--color-primary);
	color: var(--color-truewhite);
}


:global(.tabs__panels) {
	padding-top: var(--size-16);
	outline: none !important;


	@media (--tablet) {
		padding-top: var(--size-24);
	}

	@media (--desktop) {
		padding-top: var(--size-32);
	}
}


:global(.tabs__panel) {
	display: flex;
	flex-direction: column;
	padding-bottom: var(--size-16);
	outline: none !important;

	@media (--tablet) {
		padding-bottom: var(--size-24);
	}

	@media (--laptop) {
		flex-direction: row;
	}

	@media (--desktop) {
		padding-bottom: var(--size-32);

	}
}

:global(.is-hidden) {
	display: none;
}


.mainContent {
	display: flex;
	flex-direction: column;
	margin-top: var(--size-16);
	padding-top: var(--size-32);
	background: var(--color-truewhite);

	@media (--tablet) {
		flex-direction: row;
	}
}


.main {
	width: 100%;


	@media (--tablet) {
		padding-right: var(--size-24);
		width: var(--width-three-quarters);
	}
}

.hidePanelMobile {
	border-color: white;
	background: white;
	padding: 0;

	@media (--tablet) {
		padding: var(--size-24);
		border-color: var(--color-panel-bd);
		background: var(--color-whitesmoke);
	}
}


.sidebar {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: var(--size-16);


	@media (--tablet) {
		padding-top: var(--size-32);
		padding-left: var(--size-24);
		width: var(--width-one-quarter);
	}
}

.cartSideBar {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: var(--size-16);


	@media (--tablet) {
		padding-top: var(--size-32);
		padding-left: var(--size-24);
		width: 27%;
	}
}


.col {
	width: 100%;

	@media (--laptop) {
		width: 50%;
	}
}

.col:first-of-type {

	@media (--laptop) {
		padding-right: var(--size-16);
	}
}

.col:last-of-type {

	@media (--laptop) {
		padding-left: var(--size-16);
	}
}


.content {
	order: 1;
	width: 100%;


	@media (--tablet) {
		max-width: var(--width-three-fifths);
	}
}


.title {
	font-family: var(--font-helvetica);
	font-size: var(--text-lg);
	margin: 0 0 var(--size-24);
}


.ccForm {
	width: 100%;


	@media (--laptop) {
		max-width: var(--width-three-quarters);
	}
}


.hr {
	border: 0px solid var(--color-panel-bd);
	border-bottom: 1px solid var(--color-panel-bd);
	margin: var(--size-32) 0 var(--size-24);
}


.actions {
	margin-top: var(--size-32);
	margin-bottom: var(--size-16);


	@media (--tablet) {
		margin: var(--size-48) 0;
	}
}


.actionBtn {
	display: inline-block;
	width: 100%;

	@media (--tablet) {
		width: auto;
	}
}


.textLink {
	color: var(--color-primary);
	text-decoration: none;
	margin-bottom: var(--size-16);
	transition: color .3s linear;
	text-align: left;


	&:hover {
		color: var(--color-primary-dark);
	}
}

.linkToCard {
	white-space: normal;
	text-align: center;
}

.creditCardFormContainer {

	@media (--tablet) {
		padding-right: var(--size-128);
	}
}

.checkContainer {
	display: flex;
}

.saveCardText {
	margin-top: -2px;
	padding-left: 8px;
}

.btnContainer {
	margin-bottom: 50px;
}

.forgotUsernametxt {
	font-size: 14px;
	color: #165C96;
	margin: 30px 0px;
}

.resetPassContent {
	display: flex;
	flex-direction: column-reverse;

	@media (--tablet) {
		display: flex;
		flex-direction: row;
	}
}

.validationBox {

	@media (--tablet) {
		padding: 0 20px;
	}
}

.validationBoxMobile {
	padding: 0px;

	@media (--tablet) {
		display: none;
	}
}
