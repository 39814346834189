@import-once "breakpoints.css";
@import-once 'sizings.css';
@import-once "colors.css";
@import-once "typography.module.css";


.autocompleteContainer {
	margin-bottom: var(--size-56);


	@media (--tablet) {
		margin-bottom: var(--size-96);
	}
}


.autocomplete {
	margin-top: var(--size-12);
	position: relative;

	@media (--tablet) {
		position: relative;
	}
}



.autocompleteLabel {
	color: var(--color-gray);
	font-size: var(--text-base);
	font-weight: bold;
}


.autocompleteInput {
	position: relative;
	border-radius: 4px;
	margin-bottom: var(--size-16);
	border: 2px solid var(--color-primary);
	color: var(--color-gray-darkest);
	line-height: 1;
	padding: var(--size-16);
	padding-right: var(--size-56);
	width: 100%;


	@media (--tablet) {
		min-height: auto;
	}


	&:focus {
		border-color: var(--color-primary);
		box-shadow: inset 0 0 0 1px var(--color-primary);
		outline: none;
	}
}


.autocompleteBtn {
	padding: var(--size-12);
	border-radius: 5px;
	background-color: var(--color-primary);
	position: absolute;
	top: var(--size-2);
	right: var(--size-4);
	outline: none;
	border: none;
	margin-top: var(--size-4);

	&:hover {
		cursor: pointer;
	}

	@media (--tablet) {
		padding: .625rem;
		margin-top: 6px;
		margin-right: 2px;
	}
}

.autocompleteBtnIcon {
	color: #fff;
	width: var(--size-20);
}


.autocompleteOptions {
	border: 1px solid var(--color-panel-bd);
	border-radius:4px;
	position: absolute;
	top: 85%;
	z-index: 10;
	background-color: var(--color-truewhite);
	width: 100%;


	&:empty {
		border-color: transparent;
		background-color: transparent;
	}
}


.autocompleteOption {
	color: var(--color-primary-dark);
	cursor: pointer;
	padding: var(--size-8) var(--size-8) var(--size-6);
	font-weight: var(--font-bold);
	transition: background-color .3s linear;



	&:hover {
		background-color: var(--color-global-bg);
	}
}


.loading {
	padding: var(--size-4) var(--size-8);
}






.location {
	display: flex;
	margin: 0;
	font-weight: var(--font-bold);
}


.locationBtn {
	align-items: center;
	background: none;
	border: none;
	color: var(--color-primary);
	cursor: pointer;
	display: flex;
	transition: color .3s linear;
	outline: none !important;

	&:hover {
		color: var(--color-primary-dark);
	}
}


.locationIcon {
	margin-right: var(--size-8);
	width: var(--size-20);
}








.calltoActionText {
	width: 80%;
	display: flex;
	margin: 0 0 var(--size-24);
	font-size: var(--text-base);
	line-height: 1.5;
	color: var(--color-charlie-light);
}






.resultsHeader {
	padding-top: var(--size-32);


	@media (--tablet) {
		padding-top: var(--size-56);
	}
}


.resultsPanel {
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--size-16);
	margin-top: var(--size-24);
	align-items: center;
	padding: var(--size-16) var(--size-16) var(--size-12);


	@media (--tablet) {
		margin-top: var(--size-48);
		margin-bottom: var(--size-32);
	}
}


.resultsTitle {
	font-size: var(--text-4xl);
	color: var(--color-primary);
	margin: 0 0 var(--size-16);
}

.showSearchText {
	color: var(--color-primary-dark);
	font-weight: bold;
	white-space: nowrap;
	margin: 0;
	line-height: 1;
	font-family: var(--font-helvetica);
}



.textLink {
  color: var(--color-primary);
  font-size: var(--size-16);
  text-decoration: none;
   font-weight: var(--font-semibold);
}

.redoSearch{
	display: none;

	@media (--tablet) {
		display: inline;
		color: var(--color-gray);
		margin-bottom: 0;
	}
}

.redoSearchCheck {
	flex-wrap: nowrap;
	margin-bottom: 0;
}

.redoSearchCheck label {
	margin-bottom: 0;
	margin-left: var(--size-8);
}

.redoSearchCheck p {
	margin: 0;
	padding-left:var(--size-10);
}





.resultsList {
	width: 100%;
}



.resultBox {
	width: 100%;
	background-color: var(--color-whitesmoke);
	padding: var(--size-12);
	border-radius: 4px;
	border: 1px solid var(--color-panel-bd);
	margin-bottom: var(--size-16);
	transition: background-color .3s linear,
				color .3s linear;


	@media (--tablet) {
		width: var(--size-320);
		padding: var(--size-16);
	}

	&:hover {
		background-color: var(--color-primary-lightest);
		border: 1px solid var(--color-primary);
	}

	&.isSelected {
		background-color: var(--color-primary-lightest);
		border: 1px solid var(--color-primary);;
	}


	&.noBg {
		border-color: transparent;
		background-color: transparent;
		padding: 0;
		width: 100%;
	}
}


.stationTitle {
	color: var(--color-gray);
	font-size: var(--text-lg);
	font-weight: var(--font-bold);
	margin-bottom: var(--size-8);
	font-family: var(--font-helvetica);
}


.distance {
	margin: 0;
	font-size: var(--text-sm);
	color: #4a4a4a;
}




.resultText {
	color: #4a4a4a;
	font-size: var(--text-sm);
	margin-bottom: 0px;
	text-decoration: none;
}



.getDirections {
	display: flex;
	justify-content: space-between;
}

.resultAddress {
	color: #4a4a4a;
	font-weight: bold;
}

.sectionText {
	margin-bottom: var(--size-4);
	font-size: var(--text-sm);
}



.accessMedia {
	background-color: var(--color-primary);
	display: flex;
	padding: var(--size-4);
	border-radius: 3px;
	margin: auto 0;
	height: var(--size-16);
	width: var(--size-16);
}

.accessMediaIcon {
	color: var(--color-truewhite);
	width: var(--size-16);
}




.colorText {
	color: #165c96;
	cursor: pointer;
}

.linkText {
	margin-bottom: 10px;
}



.checkBoxContainer {
	display: none;

	@media (--tablet) {
		display: inline;
		margin-top: 2px;
		margin-bottom: 5px;
	}
}

.hideDisplay {
	display: none !important;
}

.showDisplay {
	display: block !important;
	max-width: 100%;

	@media (--tablet) {
		height: 600px;
		overflow-y: auto;
		max-width: 345px;
	}
}

.mapContainer {
	display: block;
	height: 600px;
	width: 100%;

	@media (--tablet) {
		margin-left: var(--size-8);
		margin-bottom: var(--size-32);
	}
}

.mapContainer svg:hover [id=gpsLocation] {
	background-color:#111 !important;
}


.leaflet-container {
	height: 100%;
}



.resultsMapContainer {
	display: flex;
	flex-direction: row;
	top: 0 !important;
}

.hideOnDesktop {
	@media (--tablet) {
		display: none;
	}
}

.titleContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (--tablet) {
		display: flex;
		flex-direction: row !important;
		justify-content: space-between;
	}
}

.titleContainerModal {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
}

.modalViewOverride {
	z-index: 1000;
}

.noLocationsText {
	margin-top: var(--size-22);
	color: var(--color-gray-darkest);
	font-weight: var(--font-bold);
}


.retailLocationContainer {
	padding-bottom: var(--size-12);


		@media (--tablet) {
			padding-top: var(--size-24);
			padding-bottom: var(--size-24);
	}
}

.retailsHours{
	font-size: var(--text-sm);
	margin-left: var(--size-8);
	font-weight: var(--font-bold);
}

.desktopHidden {
	display: block;

	@media (--tablet) {
		display: none;
	}
}
