@import-once "breakpoints.css";
@import-once "colors.css";
@import-once "sizings.css";
@import-once "typography.module.css";



.table {
	width: 100%;
	&:not(.hasFooter) {
		margin-bottom: var(--size-16);
	}
}

.purchasesTable { }
.travelTable { }
.membersTable { }

.isSortable { }
.isSelectable { }
.filtersVisible { }


.tableInfo {
	align-items: center;
	background-color: var(--color-truewhite);
	border: var(--size-1) solid var(--color-panel-bd);
	border-bottom: none;
	border-radius: var(--size-4) var(--size-4) 0 0;
	display: flex;
	justify-content: space-between;
	padding: var(--size-12) var(--size-20) var(--size-10);
	text-align: left;

	&.isDark {
		color: var(--color-truewhite);
		background-color: var(--color-trueblack);
		border-color: var(--color-trueblack);
	}
}






.tableFilters {
	display: inline;
	font-size: var(--text-base);
	margin: 0;

	@media (--tablet) {
		font-size: var(--text-lg);
		justify-content: space-between;
	}
}



.tableActions {
	display: flex;
	margin-top: var(--size-16);
	margin-inline-start: auto;
}

.printDownload {
	display: flex;
	justify-content: space-between;

	@media (--tablet) {
		width:100%;
	}
}

.pagination {
	display: flex;
	justify-content: flex-end;
}


.rowActions {
	display: flex;
	font-size: var(--text-sm);
	margin-top: var(--size-16);
	padding: var(--size-16) 0;
	justify-content: space-between;
	width: 100%;
	flex-direction: column-reverse;

	@media (--mobile) {
		border-top: 1px solid var(--color-gray-lighter);
		flex-direction: row;
		justify-content: space-between;
	}
}

.rowActionBtn {
	border: 1px solid currentColor;
	color: var(--color-gray-darkest);
	border-radius: var(--size-4);
	padding: var(--size-2) var(--size-12);
	font-size: var(--text-sm);
	transition-property: background-color, color;
	transition-duration: .3s;
	transition-timing-function: linear;


	&.isNegative {
		/* color: var(--color-red);
		background-color: var(--color-red-10p); */
	}
}

.resolveBtn {
	color: var(--color-truewhite)
}



.disputeActionMob {
	min-width: var(--size-296);
	padding: var(--size-16);
	background-color: var(--color-global-bg);
	margin-bottom: var(--size-16);
	width: 100%;

	@media (--mobile) {
		display: flex;
		justify-content: flex-end;
		align-items: baseline;
		background-color: inherit;
		padding: 0;
		margin-bottom: 0;
	}
}




.disputeChargeQuestion {
	color: var(--color-gray);
	margin-right: var(--size-8)
}

.requestRefundBtn {
	margin-left: 0;
	margin-top: var(--size-8);
	font-size: var(--text-sm);
	width: fit-content;
	font-weight: var(--font-bold);

	&:focus {
		outline: var(--size-2) solid var(--color-primary);
	}

	@media (--tablet) {
		margin-top: 0;
		margin-left: var(--size-8);
	}
}

.amountRefund {
	color: var(--color-green-dark);
}










.headerRow,
.headerRowPurchaseTable {
	background-color: var(--color-primary-dark);
	padding: var(--size-16);
	border-radius: var(--size-4) var(--size-4) 0 0;


	.isSortable & {
		padding-inline-end: var(--size-16);
	}

	.purchasesTable & {
		display: none;

		&.displayOnMobile {
			display: grid;
		}

		@media (--tablet) {
			display: grid;
		}
	}

	.membersTable & {
		border-radius: 0;
	}

	.filtersVisible & {
		border-radius: 0;
	}



	&.isSubHeaderRow {
		flex-direction: column;
		color: var(--color-gray);
		background-color: inherit;
		padding-bottom: 0;
	}
}


.headerRow,
.innerRow {
	display: grid;
	column-gap: var(--size-16);
	padding: var(--size-6);

	.purchasesTable & {

			/* mobile mode displays in the modal view, so grid settings are needed
			for both desktop and tablet */
		grid-template-columns: 1.5fr repeat(5, 1fr) var(--size-16);
	}

	.travelTable & {
		grid-template-columns: 1fr 1.5fr 1fr var(--size-16);

		@media (--mobile) {
			grid-template-columns: 1fr 1.5fr 1fr 1fr var(--size-16);
		}
	}

	.membersTable & {
		grid-template-columns: var(--size-14) repeat(8, 1fr);
	}
}


.headerRowPurchaseTable,
.innerRowPurchaseTable {
	display: none;
	word-break: break-word;

	@media (--tablet) {
		display: grid;
		column-gap: var(--size-16);

		.purchasesTable & {
			/*
			mobile mode displays in the modal view, so grid settings are needed
			for both desktop and tablet
			*/
			grid-template-columns: repeat(5, 1fr) 1.5fr var(--size-16);
			padding: var(--size-6);
		}

	}

	.travelTable & {
		grid-template-columns: 1fr 1.5fr 1fr var(--size-16);

		@media (--mobile) {
			grid-template-columns: 1fr 1.5fr 1fr 1fr var(--size-16);
		}
	}


}






.columnHeader {
	align-items: center;
	display: flex;
	color: var(--color-truewhite);
	text-align: left;
	margin: 0;
	white-space: nowrap;


	html[dir="rtl"] & {
		text-align: right;
	}

	&.isSubRowColumn {
		color: var(--color-trueblack);
		font-weight: var(--font-bold);
	}

	&.inline {
		display: inline-flex;
	}
}



.sort {
	display: flex;
	flex-direction: column;
	margin-inline-start: var(--size-8);
}


.triangle {
	display: block;
	cursor: pointer;
	width: 0;
	height: 0;
	border: var(--size-4) solid transparent;
	background: none;
	opacity: .4;
	padding: 0;
	transition: opacity .3s linear;

	&:hover {
		opacity: 1;
	}


	&.up {
		border-bottom-width: var(--size-8);
		border-bottom-color: var(--color-primary-light);
		margin-bottom: var(--size-4);
	}

	&.down {
		border-top-width: var(--size-8);
		border-top-color: var(--color-primary-light);
	}


	&.isActive {
		opacity: 1;
	}
}






.rowWrapper {
	background-color: var(--color-truewhite);
	display: flex;
	flex-direction: column;
	border-bottom: var(--size-1) solid var(--color-gray-lighter);

	&.sideBorders {

		.purchasesTable & {
			display: none;

			@media (--tablet) {
				display: block;
			}
		}

		@media (--tablet) {
			display: block;
			border-left: var(--size-1) solid var(--color-gray-lighter);
			border-right: var(--size-1) solid var(--color-gray-lighter);
		}
	}

	transition: border .3s linear;

	@media (--tablet) {
		&.isExpanded {
			border-left-color: var(--color-panel-bd-exp);
			border-left-width: var(--size-2);

			&.isDeclined {
				border-left-color: var(--color-red-dark);
			}
		}
	}


	&:last-of-type {
		border-radius: 0 0 var(--size-4) var(--size-4);
	}

	&:nth-child(odd) {
		background-color: var(--color-whitesmoke);
	}


	&.isExpanded {
		border-left-color: var(--color-panel-bd-exp);
		border-left-width: var(--size-4);

		&.isDeclined {
			border-left-color: var(--color-red-dark);
		}
	}


	&.isDeclined {
		background-color: var(--color-error-pink);
	}
}

.paymentDeclined {
	color: var(--color-red-dark);
	font-weight: var(--font-bold);
	margin-left: var(--size-16);
	margin-top: var(--size-16);
}

.expandedRowWrapper {

	.purchasesTable & {
		display: none;

		@media (--tablet) {
			display: block;
			padding: 0 var(--size-16);
		}
	}

	@media (--tablet) {
		display: block;
		padding: 0 var(--size-16);
	}
}




.cellDeclined {
	color: var(--color-red-dark);

	&.reason {
		font-size: var(--size-14);
	}

	&.validInspection {
		color: var(--color-gray-darkest);
	}
}

.innerRow
.innerRowPurchaseTable {
	position: relative;
	padding: var(--size-16);
	transition: padding .3s linear;


	.isExpanded & {
		padding-inline-start: var(--size-12);

		@media (--tablet) {
			padding-bottom: var(--size-12);
		}
	}


	&.isSelected {
		background-color: var(--color-primary-light);
	}
}

.isSubRowColumn { }
.subrowCell {
	font-weight: var(--font-bold);
}





.purchaseDetails {
	display: flex;
	color: var(--color-gray);
	padding-top: var(--size-16);
	border-top: 1px solid var(--color-gray-lighter);
}

.purchaseDetailLabels {
	margin-inline-end: var(--size-16);
	width: var(--width-one-third);
	font-size: var(--text-sm);


	@media (--tablet) {
		width: var(--size-160);
	}
}


.purchaseDetailValues {
	font-weight: var(--font-medium);
	font-size: var(--text-sm);
}

.tripDetails {
	color: var(--color-gray);
	font-size: var(--text-sm);
	padding: var(--size-6);
}


.tripDetailsLabels {
	display: grid;
	grid-template-columns: 1fr 1.5fr;
	border-top: var(--size-1) solid var(--color-panel-bd);

	@media (--tablet) {
		border: none;
	}
}

.tripDetailsValues {
	display: grid;
	grid-template-columns: 1fr 1.5fr;
	font-weight: var(--font-medium);
	border-bottom: var(--size-1) solid var(--color-panel-bd);

	@media (--tablet) {
		border: none;
	}
}

.fareDescription {
	color: var(--color-gray);
}




.productItem {
	&:not(:first-child) {
		font-weight: var(--font-normal) !important;
	}

	&:not(:first-of-type) {
		margin-left: var(--size-16);
	}
}

.productTripItem {
	&:first-of-type {
		width: var(--size-162);
	}

	&:not(:first-of-type) {
		margin-left: var(--size-16);
	}

	@media (--tablet) {
		&:first-of-type {
			width: var(--size-208);
		}
	}
}

.fontWeightBold {
	font-weight: var(--font-semibold);
}

.productInfo {
	display: block;
	margin-right: var(--size-16);

	@media (--mobile) {
		display: flex;
	}
}


.productTripInfo {
	display: flex;
	margin-right: var(--size-16);
	font-weight: var(--font-normal) !important;

	&:first-child {
		font-weight: var(--font-bold) !important;
	}
}


.blank {
	min-height: var(--size-24);
}

.autofilled {
	color: var(--color-red);
}


.mobileHidden {
	display: none;

	@media (--mobile) {
		display: block;
	}

}

.desktopHidden {
	display: block;
	font-size: var(--text-sm) !important;
	color: var(--color-gray-blue);

	@media (--tablet) {
		display: none;
	}
}

.hidden {
	display: none;
}










/**
 * Indv Cell styling
 */
.cell {
	font-size: var(--text-base);
	position: relative;

	&:first-of-type {
		.isSelectable & {
			align-items: center;
		}
	}
	&:last-of-type {
		display: flex;
		align-items: center;
	}


	&.cellAutofilled {
		flex-direction: column;
	}




	& .innerCellRow {
		width: var(--width-full);
		padding-right: var(--size-16);
	}

	& .innerCellRowItem {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > :first-of-type {
			margin-right: var(--size-8);
		}
	}
}



.cellDateTime {
	display: flex;
	flex-direction: column;
	width: var(--width-full);

	@media (--desktop) {
		flex-direction: row;
	}
}
.date { }
.time {
	display: flex;
	flex-direction: column;
	margin-left: 0;
	margin-inline-start: 0;

	@media (--desktop) {
		margin-left: var(--size-8);
	}
}


.cellTransitProduct {
	display: flex;
}

.fixAutofilledTrip {
	margin-top: var(--size-8);
}














.nameExpanderGroupMob {
	display: flex;
	justify-content: space-between;
	color: var(--color-gray-darkest);
	font-size: var(--size-16);
	padding: 0 var(--size-16);
}




.dateTypeGroupMob {
	display: flex;
	padding: 0 var(--size-16);
}

.locationAmountPaymentGroupMob {
	display: flex;
	justify-content: space-between;
	padding: 0 var(--size-16);
}




.transportIcon {
	height: var(--size-20);
	width: var(--size-20);
	flex-shrink: 0;
}

.transportLabel {
	margin-inline-start: var(--size-8);
}

.text { }

.dateTypeCellMob {
	color: var(--color-gray);
	padding-right: var(--size-6);
}

.locationAmountPaymentHeaderMob {
	width: var(--width-one-third);
	padding-right: var(--size-6);
}

.locationAmountPaymentCellMob {
	color: var(--color-gray);
	width: var(--width-one-third);
	font-weight: var(--font-bold);
	padding-right: var(--size-6);
}

.statusDot {
	display: inline-block;
	height: var(--size-10);
	width: var(--size-10);
	border-radius: var(--size-16);
	border: var(--size-1) solid;
	margin-inline-end: var(--size-6);

	&.isSuccess {
		border-color: var(--color-green);
		background-color: var(--color-green);
	}

	&.isWarning {
		border-color: var(--color-gray-darkest);
		background-color: var(--color-charlie-yellow);
	}

	&.isError {
		border-color: var(--color-charlie-red);
		background-color: var(--color-charlie-red);
	}
}


.cardNameGroup {
	margin: 0;
	word-break: break-all;
}

.paymentIcon {
	max-width: var(--size-40);
}

.cardIconImg {
	width: 100%;
}


.serviceLocation {
	display: flex;
}

.fitContentHeight {
	height: fit-content;
}







.downloads {

}


.tableAction {
	align-items: center;
	display: inline-flex;
	font-size: var(--text-sm);
	font-weight: var(--font-bold);


	&:not(:first-of-type) {
		margin-inline-start: var(--size-16);
	}
}

.printBtn {
	margin-right: var(--size-16);
}

.tableActionIcon {
	margin-inline-end: var(--size-8);
}

.rowExpander {
	color: var(--color-primary);
	margin-inline-start: calc(-1 * var(--size-8));
	padding: var(--size-8);

	&.isOpen {
		transform: rotate(180deg);
	}

	& i {
		width: var(--size-16);
	}
}

/* mobile rows styles */

.mobileModal {
	display: block;

	@media (--tablet) {
		display: none;
	}
}

.rowMobWrapper {
	padding: var(--size-16) 0;
}

.buttonExpMob {
	width: var(--size-32);
	border: none;
	background-color: var(--color-truewhite);
	transform: rotate(-90deg);
	outline: none;
}

.modalHeader {
	background-color: var(--color-charlie-gray);
	color: var(--color-charlie-slate);
}



.footer {
	background-color: var(--color-truewhite);
	border-bottom: var(--size-1) solid var(--color-trueblack);
	border-left: var(--size-1) solid var(--color-trueblack);
	border-right: var(--size-1) solid var(--color-trueblack);
	padding: var(--size-20);
	margin-bottom: var(--size-16);
}





.cardIconAlignment {
	display: flex;
	align-items: center;
	word-break: break-word;
}

.locale {
	display: inline-block;
	font-size: var(--text-sm);
	margin: 0;
	margin-right: var(--size-4);
	color: var(--color-gray);

	@media (--tablet) {
		font-size: var(--text-base);
		margin-right: var(--size-10);

		&.desktopDate {
			margin-right: var(--size-20);
		}
	}
}

.startsOn {
	margin-right: var(--size-8);
}

.declinedProductWrapper {}

.declinedProduct {
	display: flex;
}

.tooltipIcon svg {
	width: var(--size-16);
	height: var(--size-16);
}

.tooltip {
	padding: var(--size-4);
}

.cardImageAndMaskedPAN { }
.splitPayIcon { }

.statusWrapper {
	display: flex;
}

.displayMobile {
	display: block;

	@media (--tablet) {
		display: none;
	}
}

.displayDesktop {
	display: none;

	@media (--tablet) {
		display: block;
	}
}

.riderClassDescription {
	color: var(--color-charlie-light);
	margin-left: var(--size-8);
	display: none;

	@media (--tablet) {
		display: block;
	}
}

.pdfView {
	break-after: avoid;
	break-before: avoid;
}
