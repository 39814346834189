@import-once 'breakpoints.css';
@import-once 'sizings.css';
@import-once "colors.css";
@import-once "typography.module.css";


.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 89;
}


.container {
	background-color: var(--color-charlie-gray);
	display: flex;
	z-index: 90;


	@media (--tablet) {
		position: relative;
	}


	&.isOpen {
		z-index: 200;
	}
}


.toggle {
	align-items: center;
	align-self: stretch;
	background: none;
	border: none;
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
	color: var(--color-charlie-slate);
	cursor: pointer;
	display: flex;
	font-weight: var(--font-bold);
	min-height: var(--size-42);
	position: relative;
	padding: 0 var(--size-8);
	text-align: left;
	z-index: 200;
	transition: background-color .3s linear,
	border-color .3s linear;

	@media (--tablet) {
		padding: 0 var(--size-16);
	}

	&:focus,
	&:hover,
	&.isOpen {
		border-bottom-color: currentColor;
		background-color: var(--color-charlie-sand);
	}
}

.isOpen {
}

.linkCards {
	text-decoration: none;
	color: #77675A !important;
}

.toggleText {
	display: none;

	@media (--tablet) {
		display: block;
	}
}

.toggleArrow {
	color: currentColor;
	margin-left: var(--size-4);
	width: var(--size-8);

	.isOpen & svg {
		transform: rotate(180deg);
	}

	@media (--tablet) {
		margin-left: var(--size-8);
	}
}

.toggleIcon {
	margin-inline-end: var(--size-8);
}

.iconAvatar {
	min-width: var(--size-20);
	width: var(--size-20);
}

.iconCard {
	display: flex;
	margin-inline-start: auto;
	width: var(--size-24);
}

.iconGlobe {
	color: currentColor;
	min-width: var(--size-20);
	width: var(--size-20);
}

.iconStar {
	max-width: var(--size-12);
	width: var(--size-12);
}

.options {
	background-color: var(--color-charlie-gray);
	left: 0;
	min-width: 100%;
	position: absolute;
	top: 100%;
	z-index: 200;
	list-style-type: none;
	padding-left: 0;
	border-bottom-left-radius: var(--size-4);
	border-bottom-right-radius: var(--size-4);
	overflow: hidden;
	margin-top: 0;

	li > & {
		padding-left: var(--size--2);
	}

	@media (--tablet) {
		min-width: 100%;
		width: auto;
		white-space: nowrap;
	}
}

.options.i18n {
	@media (--tablet) {
		min-width: var(--size-125);
		width: auto;
	}
}


.optionGroup {
	border-bottom: 1px solid rgba(119, 103, 89, .2);


	&:last-of-type {
		border-bottom-color: var(--color-charlie-slate);
	}
}

.link,
.option,
.heading {
	display: flex;
	align-items: center;
	padding: var(--size-4) var(--size-12);
}

.section {
	list-style-type: none;
	padding-left: 0;

	& .option {
		padding-left: var(--size-28);
	}
}

.link,
.option {
	background-color: var(--color-charlie-gray);
	border-color: transparent;
	border-radius: 0;
	color: var(--color-charlie-slate);
	cursor: pointer;
	font-weight: var(--font-medium);
	text-align: left;
	text-decoration: none;
	transition: background-color .3s linear,
	border-color .3s linear;
	width: 100%;

	& a {
		align-items: center;
		color: var(--color-charlie-slate);
		text-decoration: none;

		&:hover,
		&:focus {
			color: var(--color-charlie-slate);
		}

		/* The rest is a quick hack to get the link to fill the entire li. @todo do this better */
		display: inline-block;
		width: calc(100% + var(--size-24));
		margin: var(--size--4) var(--size--12);
		padding: var(--size-4) var(--size-12);
		.section & {
			margin-left: var(--size--28);
			padding-left: var(--size-28);
			width: calc(100% + var(--size-40));
		}
	}

	&:hover,
	&:focus,
	&.isActive {
		background-color: var(--color-charlie-slate-20p);
		border-bottom-color: transparent;
		color: var(--color-charlie-slate);
		text-decoration: none;
	}

	& .iconCard {
		margin-left: var(--size-8);
		display: inline-block;
	}
}

.heading {
	color: var(--color-charlie-slate-hv);
	font-weight: var(--font-bold);
}

.logoutLink {
	border-top: var(--size-1) solid var(--color-charlie-slate-hv);
}

.optionIcon {
	margin-left: auto;
}
