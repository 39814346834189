@import-once "breakpoints.css";
@import-once "sizings.css";
@import-once "colors.css";
@import-once "typography.module.css";

.base {
	border-radius: var(--size-4);
	border: 1px solid transparent;
	cursor: pointer;
	font-size: var(--size-16);
	line-height: 1.25;
	padding: var(--size-12) var(--size-20);
	text-align: center;
	text-decoration: none;
	transition: background-color 0.3s linear, border-color 0.3s linear, color 0.3s linear;
	position: relative;

	&:hover {
		text-decoration: none;
	}
}

.isPrimary {
	background-color: var(--color-primary);
	border-color: var(--color-primary);
	color: var(--color-truewhite);
	outline: none;

	&:hover,
	&:focus {
		background-color: var(--color-primary-dark);
		border-color: var(--color-primary-dark);
		color: var(--color-truewhite);
		outline: none;
		overflow-wrap: break-word;
	}
}

.isSecondary {
	background-color: var(--color-truewhite);
	border-color: currentColor;
	color: var(--color-primary);
	outline: none;

	&:hover {
		color: var(--color-primary);
		background-color: var(--color-primary-lightest);
		outline: none;
	}
}

.themeLight.typeStylePlain {
	background: transparent;
	border: none;
	padding: 0;
	color: var(--color-primary);
	font-size: var(--size-14);


	&:hover,
	&:focus {
		color: var(--color-primary-dark);
	}
}



.isGhost {
	background-color: transparent;
	border-color: currentColor;
	color: var(--color-truewhite);
}

.isGhostAlt {
	background-color: transparent;
	border-color: currentColor;
	color: var(--color-gray-darkest);
	display: block;

	&:focus,
	&:hover {
		background-color: var(--color-truewhite);
	}
}

.typeStyleDanger {
	background-color: var(--color-truewhite);
	border-color: var(--color-red-dark);
	color: var(--color-red-dark);

	&:hover {
		color: var(--color-red-dark);
		background-color: var(--color-error-pink);
		outline: none;
	}
}

.isAlert {
	background-color: transparent;
	border: none;
	color: var(--color-primary);
}

.isSmall {
	padding: var(--size-4);
}

.isCharliePrimary {
	background-color: var(--color-charlie-slate);
	color: var(--color-truewhite);

	&:hover {
		color: var(--color-truewhite);
		background-color: var(--color-charlie-slate-hv);
	}

	&:focus {
		color: var(--color-truewhite);
		background-color: var(--color-charlie-slate-hv);
	}
}

.isCharlieSecondary {
	background-color: transparent;
	border-color: currentColor;
	color: var(--color-charlie-slate);

	&:hover {
		color: var(--color-charlie-slate-hv);
		background-color: rgba(240, 236, 234, 0.6);
	}

	&:focus {
		color: var(--color-charlie-slate-hv);
		background-color: rgba(240, 236, 234, 0.6);
	}
}

.isCharliePrimary,
.isCharlieSecondary {
	padding: var(--size-4) var(--size-16);
	font-size: var(--text-sm);
}



.btn:disabled,
.isDisabled {
	background-color: var(--color-gray-lighter);
	border-color: var(--color-gray-lighter);
	color: var(--color-truewhite);
	cursor: not-allowed;

	&:focus,
	&:hover {
		background-color: var(--color-gray-lighter);
		border-color: var(--color-gray-lighter);
		color: var(--color-truewhite);
	}
}

.link,
.clear {
	background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	margin: 0;

	&:hover,
	&:focus {
		background-color: transparent;
		text-decoration: underline;
	}
}

.link {
	font-weight: var(--font-semibold);

	@supports (margin-inline-start: auto) {
		margin-inline-start: auto;
	}
}

.linkBlue {
	color: var(--color-primary);

	&:hover,
	&:focus {
		text-decoration: none;
		color: var(--color-primary);
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: var(--size-36);

	@media (--mobile) {
		flex-direction: row;
	}

	&.hidden {
		display: none;
		z-index: -1;
	}

	& > .base {
		margin-bottom: var(--size-20);

		@media (--mobile) {
			margin-bottom: 0;
		}

	&:nth-of-type(even) {
		@media (--mobile) {
			margin-inline-start: var(--size-10);
		}

		@media (--tablet) {
			margin-inline-start: var(--size-20);
		}
	}
  }

	& > .typeStyleSecondary {
		@media (--mobile) {
			margin-inline-start: var(--size-10);
		}

		@media (--tablet) {
			margin-inline-start: var(--size-20);
		}
	}
}

.flexOne {
	flex: 1;
}

.isDisabled {
	& .text {
		pointer-events: none;
	}
}

.text {
	position: relative;
}

.spinner + .text {
	opacity: 0;
}

.spinner {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	& svg {
		width: var(--size-30);
		height: var(--size-30);
	}
}

.flexOne {
	flex: 1;
}

.learnMorePolicy{
	margin-top: var(--size-20);
	text-align: start;
}
