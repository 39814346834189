@import-once 'breakpoints.css';
@import-once 'sizings.css';
@import-once "colors.css";
@import-once "typography.module.css";


/*
 * Form styles go here and are imported into
 * each component so they are shared
*/
.control {
	width: 100%;


	&.check,
	&.radio {
		display: flex;
		flex-wrap: wrap;
		order: 1;
	}

	&.radioGridBox {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: var(--size-16);
		row-gap: var(--size-16);
		padding: var(--size-16);

		@media (--tablet) {
			grid-template-columns: repeat(3, 1fr);
			padding: var(--size-24);
		}
	}

	&.hidden {
		display: none;
	}
}

.box .control {
	margin-bottom: 0;
}


.label {
	display: flex;
	color: var(--color-gray-blue);
	font-size: var(--text-sm);
	font-weight: var(--font-medium);
	line-height: var(--size-16);
	cursor: pointer;
	white-space: nowrap;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: var(--size-12);
}

.calloutBox .label {
	color: var(--color-gray);
	font-weight: var(--font-normal);
	line-height: var(--size-20);
	margin-left: var(--size-10);
}

.box .label {
	color: var(--color-gray);
	font-weight: var(--font-normal);
	margin-bottom: 0;
	margin-right: var(--size-16);
	white-space: normal;
}


.optionalLabel {
	font-weight: var(--font-normal);
	font-style: italic;
	margin-left: var(--size-4);
	color: var(--color-gray-dark);
	font-size: var(--text-sm);
}

.input,
.error {
	border-radius: 4px;
	border: 1px solid var(--color-gray-lighter);
	color: var(--color-gray-darkest);
	line-height: 1;
	min-height: var(--size-56);
	padding: var(--size-12) var(--size-16) var(--size-8);
	width: 100%;


	@media (--tablet) {
		min-height: auto;
	}


	&:focus {
		border-color: var(--color-primary);
		box-shadow: inset 0 0 0 1px var(--color-primary);
		outline: none;
	}
}

.input + .label {
	margin-top: var(--size-8);
	margin-bottom: 0;
}

.error {
	border-color: var(--color-red-dark);
}

.errorMsg {
	width: 100%;
	color: var(--color-charlie-red);
	font-size: var(--text-xs);
	font-weight: var(--font-bold);
	margin-top: var(--size-8);


	@media (--tablet) {
		font-size: var(--text-base);
	}


	&:empty {
		margin: 0;
	}
}


.help {
	color: var(--color-gray-dark);
	line-height: var(--leading-tight);
	margin-top: var(--size--8);
	margin-bottom: var(--size-8);
}


.section {
	margin: var(--size-20) 0;
}


.phone {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: var(--size-20);
}

.phoneContainer {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
}

.phoneLabel {
	flex-basis: 100%;
}

.phoneHolder {
	flex: 1;
	margin-right: var(--size-16);
}

.phoneInput {
	position: relative;

	& input {
		border-radius: var(--size-4);
		border: 1px solid;
		color: var(--color-gray-dark);
		padding: var(--size-12) var(--size-16) var(--size-8) var(--size-60);
		width: 100%;
		line-height: 1;
		margin-bottom: var(--size-4);
		min-height: calc(var(--size-4) + var(--size-42));
		border-color: var(--color-gray-lighter);

		&:focus {
			border-color: var(--color-primary);
			box-shadow: inset 0 0 0 1px var(--color-primary);
			outline: none;
		}
	}

	& :global(.PhoneInputCountry) {
		position: absolute;
		top: var(--size-16);
		left: var(--size-16);
	}

	@media (--tablet) {
		min-height: auto;
	}
}

.phoneInputError {

	& input,
	& input:focus {
		border-color: var(--color-red);
		color: var(--color-red);
		box-shadow: none;
	}
}

.phoneType {
	margin-bottom: 0;
}

.phoneFieldSet {
	border: none;
	padding: 0;

	& legend {
		margin-bottom: 0;
		padding-top: var(--size-4);
	}
}

.hr {
	border: 0px solid var(--color-panel-bd);
	border-bottom: var(--size-1) solid var(--color-panel-bd);
	margin: var(--size-20) 0;
}


.title {
	display: flex;
}


.titleHelp {
	margin-left: var(--size-8);
}

.textLink {
	color: var(--color-primary);
	text-decoration: none;
}


.row {
	display: flex;

	margin: 0 var(--size--8) var(--size-16);

}

.row.groupAdmin {
	width: 100%;


	@media (--tablet) {
		max-width: var(--width-one-half);
	}
}


.rowStacked {
	display: flex;
	flex-direction: column;
	margin: 0 var(--size--8) var(--size-16);
}

.rowAddress {
	flex-wrap: wrap;
	width: 70%;

	@media (--mobile) {
		flex-wrap: nowrap;
	}
}

.addressNames {
	flex-direction: column;


	@media (--laptop) {
		flex-direction: row;
	}
}


.inlineInput,
.shortInput,
.inputCity {
	align-items: flex-end;
	margin: 0 var(--size-8);
	width: auto;

	& input {
		height: calc(var(--size-48) + var(--size-1));
	}
}

.inputCity {
	width: 100%;
	margin-bottom: var(--size-8);

	@media (--mobile) {
		width: auto;
		flex: 1;
		margin-bottom: 0;
	}
}

.inlineInput {
	flex: 1;
}

/* .shortInput { flex: .47; } */

.rowStacked .inlineInput:not(:last-child),
.rowStacked .countryDropDown:not(:last-child),
.rowStacked .shortInput:not(:last-child) {
	margin-bottom: var(--size-16);
}


.addressNames .inlineInput {
	margin-bottom: var(--size-16);

	@media (--laptop) {
		margin-bottom: 0;
	}
}


.actions {
	margin: var(--size-24) 0 var(--size-16);
}

.action {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: var(--size-20);

	@media (--mobile) {
		max-width: var(--size-296);
	}
}


.box {
	background-color: var(--color-whitesmoke);
	border-radius: var(--size-4);
	border: var(--size-1) solid var(--color-panel-bd);
	padding: var(--size-12) var(--size-24);
	margin-top: var(--size-24);
	display: flex;
	align-items: center;


	@media (--tablet) {
		max-width: var(--width-two-thirds);
	}


	&.isStacked {
		flex-direction: column;
	}

	&.isStretched {
		max-width: 100%;
		width: 100%;
	}
}

.calloutBox {
	background-color: var(--color-whitesmoke);
	border: 1px solid var(--color-panel-bd);
	border-radius: 4px;
	padding: var(--size-20) var(--size-24);
	position: relative;
}


.calloutBoxTitle {
	font-size: var(--text-sm);
	margin: var(--size-2) 0 var(--size-8);
	color: var(--color-gray-blue);
	font-weight: var(--font-medium);
}


/**
 * Password / text toggles
 */

.srOnly {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.passwordWrapper {
	display: flex;
	flex-direction: column-reverse;
	padding: var(--size-10) 0;

	@media (--tablet) {
		margin-left: var(--size-20);
		padding: 0;
	}
}

.passwordCharlieWrapper {
	display: flex !important;
	flex-direction: column-reverse;
	padding: var(--size-10) 0;

	@media (--tablet) {
		padding: 0;
	}
}

.codeGroup {
	position: absolute;
	right: 0;
	justify-content: space-between;
	padding-bottom: var(--size-8);
	color: var(--color-gray-blue);
	font-size: var(--text-sm);
	font-weight: var(--font-medium);
}

.securityCodeGroup {
	padding-bottom: var(--size-8);
	color: var(--color-gray-blue);
	font-size: var(--text-sm);
	font-weight: var(--font-medium);
}


/**
 * Input Groups
 */
.inputGroup {
	display: flex;
}

.inputGroup .control {
	margin-left: -1px;
	width: var(--size-40);
}

.inputGroup .label {
	display: none;
}

.inputGroup .input {
	border-radius: 0;
	padding: var(--size-16) var(--size-12);
	text-align: center;
}

.inputGroupFirst .input {
	border-radius: 4px 0 0 4px;
}

.inputGroupLast .input {
	border-radius: 0 4px 4px 0;
}

.checkboxLabel {
	font-weight: var(--font-bold);
	color: var(--color-gray);
	padding-left: var(--size-8);
}


/**
 * Select component - wrapper around React Select
 */


.selectContainer {
	background-color: var(--color-truewhite);
	position: relative;
	width: 100%;
	margin-top: var(--size-4);
}

.select {
	position: relative;
}

.select__control {
	background-color: var(--color-truewhite);
	box-shadow: inset 0 0 0 1px var(--color-truewhite);
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 4px;
	border-color: var(--color-gray-lighter);
	height: var(--size-48);
}

.select__control--is-focused {
	border-color: var(--color-primary);
	box-shadow: inset 0 0 0 1px var(--color-primary);
}


.select__indicators {
	background-color: var(--color-transparent);
	border-radius: 0 4px 4px 0;
	margin-left: auto;
}


.select__indicator-separator {
	display: none;
}

.select__dropdown-indicator {
	border-left: none;
	background-color: var(--color-truewhite);
	color: var(--color-primary);
	border-radius: 0 4px 4px 0;
}


.select__input {
	border-color: var(--color-primary);
	box-shadow: none;


	&:hover {
		background-color: var(--color-primary);
		box-shadow: inset 0 0 0 1px var(--color-primary);
	}
}


.select__menu {
	border-radius: 0 0 4px 4px;
	margin-top: 0;
}


.select__value-container,
.select__value-container--has-value {
	background-color: var(--color-transparent);
	border-radius: 4px 0 0 4px;
	padding: var(--size-4) var(--size-12);
}


.select__option {
	background-color: var(--color-truewhite);
	color: var(--color-primary-dark);
	cursor: pointer;


	&:hover {
		background-color: var(--color-primary-lightest);
	}
}


/**
 * Stylized Radio
 */
.radio {
	display: flex;
	align-items: center;

	& + .radio {
		margin-top: var(--size-4);
	}
}

.radioGrid {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 -4px;
}

.radioInputGrid {
	flex-basis: calc(var(--width-one-half) - 4px);
	margin: 0 2px 4px;


	@media (--mobile) {
		flex-basis: calc(var(--width-one-third) - 4px);
	}


	@media (--laptop) {
		flex-basis: calc(var(--width-one-quarter) - 4px);
	}
}


.radioInput {
	display: none;
}


.radioLabel {
	font-weight: var(--font-bold);
	display: flex;
	align-items: center;
	font-size: var(--text-base);
	color: var(--color-gray);
	cursor: pointer;
	margin-bottom: 0;


	.radioGroupOption & {
		padding: var(--size-16);
	}
}


.stackedRadio .radioLabel {

	@media (--tablet) {
		flex-direction: column-reverse;
		align-items: center;
	}
}


.radioLabel::before {
	display: block;
	border: 2px solid var(--color-primary);
	background-color: var(--color-truewhite);
	content: '';
	height: var(--size-20);
	min-width: var(--size-20);
	width: var(--size-20);
	border-radius: 50%;
	margin-right: var(--size-8);


}

.stackedRadio .radioLabel::before {

	@media (--tablet) {
		margin-right: 0;
		margin-top: var(--size-16);
	}
}

.radioLabel.radioChecked::before {
	background-color: var(--color-primary);
	box-shadow: inset 0 0 0 3px var(--color-truewhite);
}


/**
 * Radio Groups
 */
.radioGroup {
	margin-top: var(--size-16);
}

.radioGroupOptions {
	position: relative;
}

.radioGroupOption {
	border: 1px solid var(--color-panel-bd);
	margin-top: -1px;


	&:first-of-type {
		border-radius: var(--size-4) var(--size-4) 0 0;
	}

	&:last-of-type {
		border-radius: 0 0 var(--size-4) var(--size-4);
	}


	&::before {
		display: block;
		content: '';
	}
}


/**
 * Credit Card Form
 */
.cc {
	display: flex;
	margin-bottom: 0;
}

.ccInput {
	position: relative;
	margin-bottom: 0;
	width: 100%;
}

.ccIcon {
	position: absolute;
	top: 50%;
	right: var(--size-16);
	transform: translateY(-50%);
}

.ccSvg {
	width: var(--size-32);
}

.toolTipIcon {
	padding: 0;
	margin-left: var(--size-4);

	& p {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& svg {
		width: var(--size-16);
		height: var(--size-16);
	}
}

.cardNumberToolTip {
	font-size:  var(--size-14);
	& p {
		justify-content: flex-start;
	}
}

.ccFormInput {
	@media (--tablet) {
		max-width: var(--size-296);
	}


	& input {
		height: var(--size-48);
	}
}

.ccShortInput {
	@media (--tablet) {
		max-width: calc(var(--size-128) + var(--size-4));
	}

	&:first-child {
		margin-right: var(--size-32);
		margin-bottom: var(--size-16);

		@media (--tablet) {
			margin-bottom: auto;
		}
	}

}

.ccRow {
	display: block;
	margin-left: 0;

	&.cvc {
		display: block;

		@media (--tablet) {
			display: flex;
		}
	}
}

.ccText {
	font-weight: var(--font-normal);
}


/* Text area on Purchase New Charlie Card Stored Value */

.textAreaContainer {
	margin-top: var(--size-4);


	@media (--tablet) {
		max-width: calc(var(--width-three-quarters) + 10px);
	}
}

.textArea {
	width: 145px;
	border-radius: 4px;
	height: 38px;
	margin-top: 5px;
	border-color: #ccc;
	line-height: 1.7;
	line-height: 1.7;
	outline: none;
}


/* Shipping container for Purchase new charlie card */

.shipFormContainer {

	@media (--desktop) {
		padding-right: var(--size-128);
	}
}

.fieldLabel {
	color: #676C83;
	font-weight: var(--font-semibold);
	font-size: 13px;
	margin-bottom: 8px;
}


/* Hide hidden input w/o taking space on Dom used for International phone Input */

.hideInput {
	display: none;
}

.phoneNumberTypeContainer {
	margin-top: var(--size-10);

	&:nth-of-type(3) {
		margin-bottom: var(--size-24);

		@media (--tablet) {
			margin-bottom: 0px;
		}
	}
}

.stateZipRow {
	display: flex;
}

.stateZipCountryContainer {
	flex-direction: column;
}

.countryDropDown {
	margin: 4px 8px 4px 8px;
}

.leftInput {

	@media (--tablet) {
		width: 100%;
		max-width: var(--size-296);
	}
}

.leftSelect {
	width: 100%;
	min-width: var(--size-296);
}

.rightSelect {
	width: 100%;
	min-width: var(--size-112);
}

.withSuffix {
	width: 100%;

	@media (--tablet) {
		max-width: var(--size-296);
	}
}

.nicknameSuffix {
	color: var(--color-gray-blue);
	font-weight: var(--font-normal);
	position: relative;
	float: right;
	margin-left: auto;
}

.securityCode svg {
	width: 150px;
	height: auto;
	margin-top: 10px;
}


.btn {
	align-self: stretch;
	background-color: var(--color-blue);
	border-radius: var(--size-4);
	border: 2px solid var(--color-blue);
	color: var(--color-truewhite);
	cursor: pointer;
	display: flex;
	flex-grow: 1;
	justify-content: center;
	padding: var(--size-16);
	transition: background-color .3s linear,
	border-color .3s linear,
	color .3s linear;

	font-weight: var(--font-bold);


	@media (--tablet) {
		align-self: flex-start;
	}


	&:hover {
		background-color: var(--color-blue);
		border-color: var(--color-blue);
	}
}


.btn.isSecondary {
	background-color: var(--color-truewhite);
	color: var(--color-blue);
	border-color: currentColor;
	font-weight: var(--font-bold);

	@media (--mobile) {
		margin-left: var(--size-8);
	}

	@media (--tablet) {
		margin-left: var(--size-20);
	}
}
