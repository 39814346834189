@import-once "breakpoints.css";
@import-once "colors.css";
@import-once "sizings.css";
@import-once "typography.module.css";




.container {
	display: grid;
	grid-template-columns: 1fr 1fr 1.25fr var(--size-16);
	grid-template-rows: repeat(3, min-content);
	column-gap: var(--size-12);
	padding: var(--size-16);


	&.isDeclined {
		grid-template-rows: repeat(4, min-content);
	}


	&.detailView {
		padding: 0 0 var(--size-16) 0;
	}

	@media (--tablet) {
		display: none;
	}

}



.productName {
	color: var(--color-gray-darkest);
	font-size: var(--text-base);
	font-weight: var(--font-semibold);
	grid-column: 1 / 4;
	grid-row: 1 / 2;


	.isDeclined & {
		grid-row: 2 / 3;
	}
}


.chevron {
	color: var(--color-primary);
	grid-column: 4 / 5;
	grid-row: 1 / 2;
	width: var(--size-12);
	transform: rotate(-90deg);
}


.purchaseDate {
	display: flex;
	grid-column: 1 / 5;
	grid-row: 2 / 3;
	margin-bottom: var(--size-16);

	.isDeclined & {
		grid-row: 3 / 4;
	}
}


.purchaseType {
	margin-inline-start: var(--size-12);
}



.location {
	grid-column: 1 / 2;
	grid-row: 3 / 4;
}


.amount {
	grid-column: 2 / 3;
	grid-row: 3 / 4;
}


.payment {
	grid-column: 3 / 4;
	grid-row: 3 / 4;
}


.fadedText {
	color: var(--color-gray-blue);
	font-size: var(--text-sm);
	font-weight: var(--font-normal);
	margin-bottom: 0;
}


.purchaseStat { }

.purchaseValue {
	color: var(--color-gray);
	font-size: var(--text-sm);
	font-weight: var(--font-semibold);
	margin-bottom: 0;
}
