@import-once 'sizings.css';

:root {

	/**
	* Typography
	*/
	--font-helvetica: 'Helvetica Neue', Helvetica, system-ui, -apple-system, sans-serif;
	--font-inter: 'Inter UI', Helvetica, system-ui, -apple-system, sans-serif;

	                      /* Mobile / Desktop */
	--text-xs   : .75rem;   /* 10px / 12px */
	--text-sm   : .875rem;  /* 12px / 14px */
	--text-base : 1rem;     /* 14px / 16px */
	--text-lg   : 1.125rem; /* 16px / 18px */
	--text-xl   : 1.25rem;  /* 18px / 20px */
	--text-2xl  : 1.5rem;   /* 21px / 24px */
	--text-3xl  : 1.625rem; /* 26px (desktop only) */
	--text-4xl  : 2rem;     /* 28px / 32px */
	--text-5xl  : 2.285rem; /* 32px (mobile only) */
	--text-6xl  : 2.5rem;   /* 40px (desktop only)*/
	--text-7xl  : 2.875rem; /* 40px / 46px */


	--font-hairline: 100;
	--font-thin: 200;
	--font-light: 300;
	--font-normal: 400;
	--font-medium: 500;
	--font-semibold: 600;
	--font-bold: 700;
	--font-extrabold: 800;
	--font-black: 900;

	--font-style-normal: normal;
	--font-style-italic: italic;
	--font-style-oblique: oblique;

	--leading-loose: 1.5;
	--leading-normal: 1.3;
	--leading-tight: 1.2;

}

/*
 * Typography classes matching sizing from Styleguide
 */
.h7 {
	font-size: var(--text-base);
	font-weight: var(--font-bold);

	@media (--tablet) {
		font-size: var(--text-lg);
	}
}

.h8 {
	font-size: var(--text-sm);
	font-weight: var(--font-bold);

	@media (--tablet) {
		font-size: var(--text-base);
	}
}

.fontWeightLight {
	font-weight: var(--font-light);
}

.italic {
	font-style: var(--font-style-italic);
}
