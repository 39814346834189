:root {

	/**
	* Colors
	*/
	--color-trueblack     : #000;
	--color-gray-darkest  : #1C1E23;
	--color-gray-dark     : #4a4a4a;
	--color-gray          : #494F5C;
	--color-gray-blue     : #676C83;
	--color-gray-light    : #788093;
	--color-gray-lighter  : #B0B5C0;
	--color-gray-lightest : #E9EAED;
	--color-gray-hover    : #F3F3F3;
	--color-whitesmoke    : #FAFAFB;
	--color-white-frost   : #F3F7FA;
	--color-truewhite     : #FFF;
	--color-truewhite-80p : rgba(255, 255, 255, .8);
	--color-transparent   : transparent;


	--color-primary-dark     : #0B2F4C;
	--color-primary          : #165C96;
	--color-primary-light    : #5DA9E8;
	--color-primary-lighter  : #A1C6ED;
	--color-primary-lightest : #CEE0F4;

	--color-primary-lightest-75p: rgba(226,236,249,0.75);

	--color-red        : #DA291C;
	--color-red-10p    : rgba(218, 41, 28, .1);
	--color-red-dark   : #B3000F;
	--color-green      : #3C7E01;
	--color-green-dark : #145A06;
	--color-blue       : #1C77C3;
	--color-light-blue : #D5ECF5;
	--color-banner-blue : #186F90;
	--color-banner-purple : #95227D;

	--color-error-pink : #F2DEDE;
	--color-success-green : #DFF0D8;

	--color-charlie-slate-hv: #594B40;
	--color-charlie-slate  : #77675A;
	--color-charlie-slate-20p : rgba(119, 103, 90, .2);
	--color-charlie-light  : #7D7771;
	--color-charlie-sand   : #E4DEDC;
	--color-charlie-tan    : #D6CFCA;
	--color-charlie-gray   : #F0ECEA;
	--color-charlie-red    : #EF4223;
	--color-charlie-blue   : #2DA1CB;
	--color-charlie-blue-alt : #2995BC;
	--color-charlie-purple : #93287B;
	--color-charlie-yellow : #FFCB05;
	--color-charlie-green  : #80B141;
	--color-charlie-yellow-to-red : linear-gradient(90deg, rgba(255,203,5,1) 0%, rgba(239,66,35,1) 100%);
	--color-chalie-green-to-blue : linear-gradient(90deg, rgba(128,177,65,1) 0%, rgba(45,161,203,1) 100%);
	--color-charlie-purple-to-green : linear-gradient(90deg, rgba(147,40,123,1) 0%, rgba(128,177,65,1) 100%);
	--color-charlie-tan-radial : radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(239,235,224,1) 100%);
	--color-charlie-tan-linear: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(239,235,224,1) 100%);
	--color-charlie-tan-semi-radial: linear-gradient(180deg, rgba(240,236,234,1) 0%, rgba(255,255,255,0) 100%);

	--color-mode-subway  : #494F5B;
	--color-mode-trolley : #DA291C;
	--color-mode-bus     : #FFC72C;
	--color-mode-ferry   : #008EAA;
	--color-mode-ferry-dark : #004B5A;
	--color-mode-rail    : #80276C;


	--color-global-bg : #F2F3F5;
	--color-panel-bd  : #DBDDE3;
	--color-panel-bd-exp: #5BAAEA;


	--color-facebook : #3b5998;
	--color-google   : #3F85F7;
	--color-samsung  : #034EA2;
	--color-twitter  : #1DA1F2;
	--color-apple : #FFF;

	--color-positive-validator : radial-gradient(circle, rgba(1,130,102,1) 0%, rgba(1,78,61,1) 100%);
	--color-negative-validator : radial-gradient(circle, rgba(203,25,0,1) 0%, rgba(138,17,0,1) 100%);
	--color-caution-validator :  radial-gradient(circle, rgba(248,204,85,1) 0%, rgba(174,143,60,1) 100%);



	--shadow-tooltip:  0px 1px 6px -2px rgba(0, 0, 0, .38);





	--transition-colors: background-color, border-color, color;
}
