@import-once 'breakpoints.css';
@import-once 'sizings.css';
@import-once "colors.css";
@import-once "typography.module.css";





.container {
	display: flex;
	flex-direction: column;


	@media (--tablet) {
		padding-top: var(--size-16);
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}



.header {
	border-bottom: 1px solid var(--color-panel-bd);
	display: flex;
	flex-wrap: wrap;
	margin: 0 calc(-1 * var(--size-16));
	position: relative;
	margin-left: var(--size-10);
	margin-right: var(--size-10);
	justify-content: space-between;

	@media (--tablet) {
		margin: 0;
		border-bottom: 0;
		width: 100%;
	}


	&.cardLayout {
		margin-bottom: var(--size-16);

		@media (--tablet) {
			margin-bottom: 0;
		}
	}
}

.account .header {
	display: none;
	padding-top: var(--size-16);


	@media (--tablet) {
		display: block;
		padding-top: var(--size-32);
	}
}

.tripsHeader {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-bottom: 1px solid var(--color-panel-bd);
	color: var(--color-gray-darkest);
	position: relative;
	margin: 0 0 var(--size-14);
	padding-bottom: var(--size-8);
	width: 100%;

	@media (--tablet) {
		margin: 0 0 var(--size-30);
		padding-bottom: 0;
		border-bottom: var(--size-4) solid var(--color-primary);


	}

}

.headerAction {
	margin-inline-start: auto;
	font-size: var(--text-base);
	padding-top: var(--size-14) !important;
	font-weight: var(--font-bold);
	letter-spacing: 0;
}


.sidebar {
	display: none;


	@media (--tablet) {
		position: relative;
		display: block;
		padding-top: var(--size-24);
		width: var(--width-one-quarter);
	}

	@media (--desktop) {
	}
}


.sidebarBtn {
	display: block;
	margin-bottom: var(--size-32);
	width: 100%;
	word-spacing: normal;
}


.sidebarLink {
	align-items: center;
	color: var(--color-primary);
	display: flex;
	font-size: var(--text-base);
	font-weight: var(--font-bold);
	text-decoration: none;
}

.sidebarLinkIcon {
	margin-left: var(--size-4);
	width: var(--size-12);
}

.sidebarItem {
	margin-bottom: var(--size-20);
	white-space: normal;
}




.pageColWrapper {

	@media (--laptop) {
		display: flex;
	}
}

.isSplit { }

.main {
	padding: var(--size-16) 0;
	width: 100%;

	@media (--tablet) {
		padding: var(--size-24);
		width: var(--width-three-quarters);
	}
}

.security {

  @media (--tablet) {
	  display: flex;
  }
}

.pageCol {
	width: 100%;

	@media (--laptop) {
		width: 60%;
	}
}

.pageCol:first-of-type {

	@media (--laptop) {
		padding-right: var(--size-16);
	}
}

.pageCol:last-of-type {

	@media (--laptop) {
		padding-left: var(--size-16);
	}
}


.mainCol {
	width: 100%;

	@media (--laptop) {
		width: var(--width-three-fifths);
		padding-inline-end: var(--size-16)
	}

	.isSplit & {
		@media (--laptop) {
			width: var(--width-one-half);
		}
	}
}

.secondaryCol {
	width: 100%;

	@media (--laptop) {
		width: var(--width-two-fifths);
		padding-inline-start: var(--size-16)
	}

	.isSplit & {
		@media (--laptop) {
			width: var(--width-one-half);
		}
	}
}







.cardSelector {
	position: absolute;
	width: 100%;
	border: none;
}

@value isOpen from "../components/Dropdown.module.css";

.cardSelectorDropdown {
	position: relative;
	top: -1px;
	width: 100%;
	min-width: fit-content;
	border: 1px solid var(--color-primary);
}

.cardSelectorToggle {
	border: 1px solid var(--color-primary);
	background-color: var(--color-truewhite);

	& i {
		position: absolute;
		right: 0;
		padding: 0 var(--size-8);
		width: var(--size-30);
		background: linear-gradient(to right, var(--color-transparent), var(--color-truewhite) 20%);
		display: inline-block;
	}

	&.isOpen {
		border-bottom: none;
		z-index: 101;
	}
}



.mobileHeaderNav {
	@media (--tablet) {
		display: none;
	}
}


.sideNav {
	border-radius: var(--size-4);
	border: 1px solid var(--color-primary);
	display: none;
	flex-direction: column;
	margin-bottom: var(--size-32);
	overflow: hidden;


	@media (--tablet) {
		display: flex;
	}


	&.isMobile {
		margin: var(--size--16);
		box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
		position: absolute;
		left: 0;
		right: 0;


		@media (--tablet) {
			display: none;
		}
	}

	&.isOpen {
		display: flex;
		z-index: 110;
	}
}

.cardSideNav {
	margin-top: var(--size-68);
}


.sideNavLink {
	background-color: var(--color-truewhite);
	border-bottom: 1px solid var(--color-primary);
	color: var(--color-primary);
	font-weight: var(--font-bold);
	padding: var(--size-12) var(--size-16);
	text-decoration: none;
	transition: background-color .3s linear,
				color .3s linear;

	&:hover,
	&.isActive {
		background-color: var(--color-primary-lightest);
		color: var(--color-primary);
		text-decoration: none;
	}
}

.sideNavLink:first-of-type {
	border-radius: var(--size-4) var(--size-4) 0 0;
}

.sideNavLink:last-of-type {
	border-bottom: none;
}

.sideNavOverlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
}


.mobilePageMenu {

	@media (--tablet) {
		display: none;
	}

	&.mobileCardInfo {
		padding-left: var(--size-16);
		color: var(--color-gray);
		font-size: var(--text-sm);
	}
}





.pageTitle {
	border-bottom: var(--size-4) solid var(--color-primary);
	color: var(--color-gray-darkest);
	display: flex;
	font-family: var(--font-helvetica);
	font-size: var(--size-26);
	font-weight: var(--font-bold);
	letter-spacing: 0;
	width: 100%;
	word-break: break-all;


	@media (--tablet) {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 var(--size-30);
		padding: 0 0 var(--size-8);
	}


	&.desktopOnly {
		display: none;


		@media (--tablet) {
			display: flex;
		}
	}
}

.header > .pageTitle {
	border-bottom: 0;
	align-items: baseline;

	@media (--tablet) {
		border-bottom: var(--size-4) solid var(--color-primary);
		padding-bottom: 0;
	}
}

.tripsPageTitle {
	display: flex;
	font-family: var(--font-helvetica);
	width: auto;
	color: var(--color-gray-darkest);
	font-size: var(--size-26);
	font-weight: var(--font-bold);
	letter-spacing: 0;
	word-break: break-all;
	margin-bottom: 0;


	@media (--tablet) {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
	}
}

.pageTitleText {
	color: var(--color-gray-darkest);
	font-size: var(--text-3xl);
	line-height: var(--leading-loose);
	width: 100%;


	@media (--tablet) {
		width: auto;
	}
}


.pageTitleIcon {
	color: var(--color-primary);
	width: var(--size-24);
	margin-left: var(--size-8);

	@media (--tablet) {
		display: none;
	}
}

.pageTitleIconUnregistered {
	color: var(--color-primary);
	width: var(--size-24);
	margin-left: var(--size-8);
	display: none;

	@media (--tablet) {
		display: none;
	}
}


.pageSubPageTitle {
	color: var(--color-gray-darkest);
	font-weight: var(--font-bold);
	font-family: var(--font-helvetica);
	font-size: var(--text-3xl);
	line-height: 1.2;



	@media (--tablet) {
		color: var(--color-gray);
		font-weight: var(--font-light);
		font-size: var(--text-xl);
		margin-left: var(--size-16);
	}
}


.subPageTitle {
	color: var(--color-gray-darkest);
	font-weight: var(--font-bold);
	font-size: var(--text-3xl);
}



.subTitle {
	font-family: var(--font-helvetica);
	font-weight: var(--font-bold);
	color: var(--color-gray);
	margin: var(--size-16) 0 var(--size-8);
	font-size: var(--text-lg);
}





.rightNav {
	padding-top: 0;
	display: flex;
	flex-direction: column;

	@media (--tablet) {
		width: var(--width-one-quarter);
		padding-top: var(--size-60);
		padding-inline-start: var(--size-32);
	}
}



.titleAction {
	margin-inline-start: auto;
}



.pageSubPageTitle {
	color: var(--color-gray-darkest);
	font-weight: var(--font-bold);
	font-family: var(--font-helvetica);
	font-size: var(--text-3xl);
	line-height: 1.2;



	@media (--tablet) {
		color: var(--color-gray);
		font-weight: var(--font-light);
		font-size: var(--text-xl);
		margin-left: var(--size-16);
	}
}


.subPageTitle {
	color: var(--color-gray-darkest);
	font-weight: var(--font-bold);
	font-size: var(--text-3xl);
}



.subTitle {
	font-family: var(--font-helvetica);
	font-weight: var(--font-bold);
	color: var(--color-gray);
	margin: var(--size-16) 0 var(--size-8);
	font-size: var(--text-lg);
}


.intro {
	font-size: var(--text-base);
	color: var(--color-gray);
	margin-top: 0;
}






.rightNav {
	padding-top: 0;
	display: flex;
	flex-direction: column;

	@media (--tablet) {
		width: var(--width-one-quarter);
		padding-top: var(--size-60);
		padding-inline-start: var(--size-32);
	}
}




.section {
	& + .section {
		margin-top: var(--size-24);
	}
}


.sectionHeading {
	display: flex;
	align-items: center;
	justify-content: space-between;
}


.sectionTitle {
	margin: 0;
}



.stats {
	display: grid;
	grid-template-columns: auto repeat(5, 1fr);
	column-gap: var(--size-24);
	width: 100%;
	font-size: var(--text-sm);
}

.statImage {
	align-self: center;
}

.statLabel {
	color: var(--color-gray-blue);
	margin: 0;
	white-space: nowrap;
}

.statValue {
	font-weight: var(--font-semibold);
	color: var(--color-gray);
	margin: var(--size-8) 0 0;
}



.subNav {
	margin-top: var(--size-16);
}


