@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 400;
  src: url("../../node_modules/dotcomchrome/fonts/Inter-UI-Regular.woff2") format("woff2"),
       url("../../node_modules/dotcomchrome/fonts/Inter-UI-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 400;
  src: url("../../node_modules/dotcomchrome/fonts/Inter-UI-Italic.woff2") format("woff2"),
       url("../../node_modules/dotcomchrome/fonts/Inter-UI-Italic.woff") format("woff");
}


@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 500;
  src: url("../../node_modules/dotcomchrome/fonts/Inter-UI-Medium.woff2") format("woff2"),
       url("../../node_modules/dotcomchrome/fonts/Inter-UI-Medium.woff") format("woff");
}

@font-face {
	font-family: 'Inter UI';
	font-style:  normal;
	font-weight: 700;
	src: url("../../node_modules/dotcomchrome/fonts/Inter-UI-Bold.woff2") format("woff2"),
	url("../../node_modules/dotcomchrome/fonts/Inter-UI-Bold.woff") format("woff");
}

/* MBTA didn't provide us with the following so use our versions */
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 500;
  src: url("./fonts/Inter-UI-MediumItalic.woff2") format("woff2"),
	   url('./fonts/Inter-UI.var.woff2') format("woff2-variations"),
       url("./fonts/Inter-UI-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 700;
  src: url('./fonts/Inter-UI.var.woff2') format("woff2-variations"),
       url("./fonts/Inter-UI-BoldItalic.woff2") format("woff2"),
       url("./fonts/Inter-UI-BoldItalic.woff") format("woff");
}

/* Only used for the header/footer but fonts can't be defined inside a shadow root which is how we're sandboxing them */
@font-face {
	font-family: "Font Awesome 6 Free";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("../../node_modules/dotcomchrome/fonts/fa-regular-400.woff2") format("woff2"), url("../../node_modules/dotcomchrome/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
	font-family: "Font Awesome 6 Brands";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("../../node_modules/dotcomchrome/fonts/fa-brands-400.woff2") format("woff2"), url("../../node_modules/dotcomchrome/fonts/fa-brands-400.ttf") format("truetype");
}

@font-face {
	font-family: "Font Awesome 6 Free";
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url("../../node_modules/dotcomchrome/fonts/fa-solid-900.woff2") format("woff2"), url("../../node_modules/dotcomchrome/fonts/fa-solid-900.ttf") format("truetype");
}


