@import-once "breakpoints.css";
@import-once "colors.css";
@import-once "sizings.css";
@import-once "typography.module.css";

.container {
	width: 100%;
	max-width: 780px;
	display: flex;
	flex-direction: column;
	align-self: center;
	background-color: var(--color-truewhite);
	box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
	padding: 0 var(--size-16) var(--size-2) var(--size-16);

	@media (--tablet) {
		padding: 0px var(--size-32) var(--size-32) var(--size-32);
	}
}

.title {
	color: var(--color-charlie-slate-hv);
	font-family: Helvetica, sans-serif;
	margin-bottom: var(--size-24);
	margin-top: var(--size-40);
	font-size: var(--size-24);
}

.form {
	width: 100%;
	max-width: 680px;
	margin: auto;
	margin-top: 0;
	display: flex;
	flex-direction: column;
}

.inputWrapper {
	margin-bottom: var(--size-18);
	width: 100%;

	@media (--laptop) {
		max-width: var(--size-320);
		margin-right: var(--size-20);
		margin-bottom: 0;
	}
}

.passwordContainer {
	width: 100%;
	display: block;

	@media (--laptop) {
		justify-content: space-between;
		display: flex;
	}
}

.passwordWrapper {
	display: block;

	@media (--tablet) {
		display: flex;
		align-items: center;
	}
}

.fullWidth {
	width: 100%;
}

.passwordValidation {
	margin-left: 0;

	@media (--tablet) {
		margin-left: var(--size-20);
	}


	@supports (margin-inline-start: 1rem) {
		margin-left: 0;
		margin-inline-start: 0;

		@media (--tablet) {
			margin-inline-start: var(--size-20);
		}
	}
}

.btnWrapper {
	margin-top: var(--size-20);
}

.pwInput {
	margin-bottom: var(--size-8);
}

.pwInputContainer {
	margin-bottom: var(--size-20);
}

.submitBtn {
	width: 100%;
	@media (--laptop) {
		max-width: var(--size-320);
	}
}

.twoFactorTooltip {
	min-width: var(--size-320);
	padding: var(--size-16);
}

.twoFactorContentWrapper {
	flex-direction: column;
	@media (--tablet) {
		flex-direction: row;
	}
}

.twoFactorSidebar {
	margin-top: var(--size-24);
	@media (--tablet) {
		margin-left: var(--size-128);
	}
}

.resendCodeText {
    margin-bottom: 8px;
}
