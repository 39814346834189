@import-once 'breakpoints.css';
@import-once 'sizings.css';
@import-once "colors.css";
@import-once "typography.module.css";


.container {
	position: relative;
	width: 100%;
}

.primary {}

.secondary {}

.guest {}



.tabList {
	display: flex;
	justify-content: flex-start;
	list-style: none;
	margin: 0;
	padding: 0;
	transition: background-color .3s linear,
		border-color .3s linear;

	@media (--tablet) {
		margin-bottom: var(--size-24);
	}



	.primary & {
		background-color: var(--color-truewhite);
		border-bottom: 4px solid var(--color-primary);
		border-top: 1px solid var(--color-primary);
		border-right: 1px solid var(--color-primary);
	}

	.secondary & {
		background-color: var(--color-charlie-sand);
	}

	.guest & {

		@media (--tablet) {
			width: var(--width-full);
		}
	}
}

.tabItem {
	cursor: pointer;
	font-weight: var(--font-medium);
	margin-bottom: 0;
	border-color: transparent;
	border-bottom: var(--size-4) solid transparent;
	padding: var(--size-8) var(--size-12);
	transition: background-color .3s linear,
		border-color .3s linear,
		color .3s linear;

	@media (--tablet) {
		padding: var(--size-14) var(--size-24);
	}

	.primary & {
		background-color: var(--color-truewhite);
		color: var(--color-primary);
		border-bottom: 1px;
		border-left: 1px solid var(--color-primary);
	}

	.secondary & {
		background-color: var(--color-charlie-slate-20p);
		border-radius: var(--size-4) var(--size-4) 0 0;
		border: none;
		border-bottom: var(--size-4) solid transparent;
		color: var(--color-charlie-slate);
	}

	&:hover {
		.primary & {
			background-color: var(--color-primary-lightest);
		}

		.secondary & {
			border-bottom: var(--size-4) solid var(--color-charlie-slate);
			background-color: var(--color-truewhite);
		}
	}


	&:not(:first-of-type) {
		.secondary & {
			margin-inline-start: var(--size-16);
		}

		.guest & {
			margin: 0;
		}
	}


	.primary & {
		background-color: var(--color-truewhite);
		color: var(--color-primary);
		border-bottom: 1px;
		border-left: 1px solid var(--color-primary);
	}

	.secondary & {
		background-color: var(--color-charlie-slate-20p);
		border-radius: var(--size-4) var(--size-4) 0 0;
		color: var(--color-charlie-slate);
	}


	.guest & {
		width: var(--width-one-half);
		text-align: center;
	}



	&.isSelected {
		font-weight: var(--font-medium);

		.primary & {
			background-color: var(--color-primary);
			color: var(--color-truewhite);
		}

		.secondary & {
			background-color: var(--color-truewhite);
			border: 1px solid var(--color-white-frost);
			border-bottom: var(--size-4) solid transparent;
		}


		&:hover {
			.primary & {
				background-color: var(--color-primary);
			}
		}
	}
}





.takeover {}

.tabHeader {

	.primary & {}

	.secondary & {
		background: var(--color-charlie-sand);
	}

	.takeover & {
		margin: 0 calc(-1 * var(--size-16));
		padding: 0 var(--size-16);
	}
}





.tabPanels {}

.tabPanel {}

.tabPanelTitle {
	color: var(--color-gray-darkest);


	@media (--tablet) {
		display: none;
	}
}


.main {
	width: 100%;


	@media (--tablet) {
		padding-right: var(--size-24);
		width: var(--width-three-quarters);
	}
}

.enablementFee {
	width: 100%;
	display: inline;
	padding-top: var(--size-16);


	@media (--tablet) {
		display: flex;
		padding-top: var(--size-32);
	}
}
